import React from "react";
// import { useTranslation } from "react-i18next";

import PageStandard from "Components/Page";
//import PageYellowImg from "Assets/images/page-yellow.png";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import team1Img from "Assets/images/gerente.png";
import team2Img from "Assets/images/laura.png";
import team3Img from "Assets/images/fabian.png";
import team4Img from "Assets/images/alex.png";
import team5Img from "Assets/images/wilson.png";
import team6Img from "Assets/images/elvis.png";
import teamVictor from "Assets/images/victor-team.png";
import teamAlvaro from "Assets/images/alvaro-team.png";
import teamAdriana from "Assets/images/adriana-team.png";
import teamAdriana2 from "Assets/images/adriana2-team.png";
import teamJimena from "Assets/images/jimena.webp";
import teamVanne from "Assets/images/vanne.png";
import teamLuis from "Assets/images/luis_team.png";
import teamKevin from "Assets/images/kevin-team.webp";
import teamSara from "Assets/images/sarita.team.png";
import teamLina from "Assets/images/lina.png";
import teamLeonardo from "Assets/images/leonardo-team.webp";
import "./Team.css";
import PageHeroStandard from "Components/PageHeroStandard";
import team from "Assets/images/future.png";
import Section from "Components/Section";
import { Helmet } from "react-helmet";

const collection = [
  [team1Img, "Oscar Alvarez", "Director General", "from-gray-900  to-gray-600"],
  [
    team2Img,
    "Laura Montaño",
    "Directora Administrativa",
    "from-yellow-500  to-yellow-200",
  ],
  [
    team3Img,
    "Fabian Diaz",
    "Consultor Funcional ERP",
    "from-gray-900  to-gray-600",
  ],
  [
    team4Img,
    "Alexander Fuquen",
    "Coordinador de Implementación y Soporte",
    "from-yellow-500  to-yellow-200",
  ],
  [
    team5Img,
    "Wilson Gomez",
    "Director de Tecnología",
    "from-yellow-500  to-yellow-200",
  ],
  [team6Img, "Elvis Gil", "Desarrollador Back-End", "from-gray-900  to-gray-600"],
  [
    teamJimena,
    "Jimena Quitán Pérez",
    "Jefe de ventas",
    "from-gray-900  to-gray-600",
  ],
  [
    teamVictor,
    "Victor Ruiz Reyes",
    "Desarrollador Front-End",
    "from-gray-900  to-gray-600",
  ],
  [
    teamAlvaro,
    "Alvaro Torres Posada",
    "Director Financiero",
    "from-gray-900  to-gray-600",
  ],
  [
    teamAdriana,
    "Adriana Tamayo Salas",
    "Contadora",
    "from-yellow-500  to-yellow-200",
  ],
  [
    teamAdriana2,
    "Adriana Diaz Otalvarez",
    "Tesorera",
    "from-gray-900  to-gray-600",
  ],
  [
    teamVanne,
    "Vanessa Hernandez Barbosa",
    "Consultor Funcional",
    "from-yellow-500  to-yellow-200",
  ],
  [
    teamLuis,
    "Luis González Piñeros",
    "Jefe de Mercadeo",
    "from-gray-900  to-gray-600",
    
  ],
  [
    teamKevin,
    "Kevin Guitierrez Mendez",
    "Desarrollador IA",
    "from-yellow-500  to-yellow-200",

  ],
  [
    teamSara,
    "Sara Alvarez Montero",
    "Consultor Funcional ERP",
    "from-gray-900  to-gray-600",
    
  ],
  [
    teamLeonardo,
    "Leonardo Montoya Díaz",
    "Consultor Hotelero",
    "from-yellow-500  to-yellow-200",

  ],
  [
    teamLina,
    "Lina María Estevez",
    "Consultor Funcional ",
    "from-gray-900  to-gray-600",
  ],
  

];

const getItemTeam = (team, index) => {
  return (
    <div className="mt-48 lg: md:mb-20 lg:mb-0 lg:mt-10 " key={index}>
      <div
        className={`h-40  bg-gradient-to-tl  relative flex justify-center ${team[3]}`}
      >
        <div className="w-full absolute bottom-0 left-0 flex justify-center">
          <img src={team[0]} className="gray-scale lg:h-80 w-auto invert grayscale" />
        </div>
      </div>
      <div className="flex flex-col py-5 space-y-1 bg-gra">
        <h2 className="font-lato font-semibold text-1xl uppercase text-blue-900">{team[1]}</h2>
        <span className="text-gray-500 text-xs font-roboto">{team[2]}</span>
      </div>
    </div>
  );
};

const Team = ( ) => {
  // const { t } = useTranslation();
  return (
    <PageStandard>
        <Helmet>
        <title>Equipo Presik de soluciones  ERP, CRM y MRP | Presik S.A.S </title>
        <meta name="description" content="Equipo Presik de soluciones  ERP, CRM y MRP Colombia | Presik S.A.S" />
      </Helmet>
      <PageHeroStandard backgroundHero="imageRoundHeroText" image={team}>
        <PageTitle title="team.title" textAling="text-center" bgColor="bg-gray-600" />
        <PageDescription text="team.content" textAling="text-center"  />
      </PageHeroStandard>
        <Section>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-10 gap-y-20 md:gap-y-32 lg:gap-y-40 mt-20 mx-auto md:px-52 px-5 backdrop-grayscale">
          {collection.map((team, index) => (
            <>{getItemTeam(team, index)}</>
          ))}
        </div>
        </Section>
    </PageStandard>
  );
}

export default Team;
